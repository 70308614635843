/**
 * Module for shared loader utils. Rules for this file:
 *
 * - No imports to anything
 * - Keep it small
 * - The utils must be used in lazy/index.ts and lazy/react.tsx
 * - All code must run in IE11 without any polyfills
 */

/**
 * Returns trues when VS should be open
 *
 * @param instanceId
 */
export function isSearchActive(instanceId?: string) {
    instanceId = instanceId || "vs";
    if (typeof window === "undefined") {
        return false;
    }

    // Must work without polyfills, hence the .indexOf usage and not URLSearchParams
    return window.location.search.indexOf(instanceId + "_q=") !== -1;
}

export function createEmitter<Args extends any[] = []>() {
    type Fn = (...args: Args) => any;
    let listeners = [] as Fn[];

    return {
        once(fn: Fn) {
            listeners.push(fn);
        },
        emit(...args: Args) {
            listeners.forEach((fn) => {
                fn(...args);
            });
            listeners = [];
        },
    };
}

let asserted = false;

export function assertMainEntryNotLoaded() {
    if (typeof window === "undefined") {
        return;
    }

    if (asserted) {
        return;
    }

    if ("rvs-loaded" in window && process.env.NODE_ENV !== "production") {
        console.warn(
            `[RVS] Bad lazy loading setup! The '@valu/react-valu-search' entry is already imported. Ensure 'import "@valu/react-valu-search";' is not executed before the .load() call`,
        );
    }

    asserted = true;
}

const polyfillEmitter = createEmitter();
let polyfillStatus: "waiting" | "loading" | "done" = "waiting";

export function loadPolyfill(
    cb: () => any,
    options?: { url?: string; isModern?: boolean },
) {
    if (!options) {
        options = {};
    }

    polyfillEmitter.once(cb);

    if (polyfillStatus === "done") {
        return polyfillEmitter.emit();
    }

    if (polyfillStatus === "loading") {
        return;
    }

    let modern: boolean;

    if (options.isModern !== undefined) {
        modern = options.isModern;
    } else {
        modern = isModern();
    }

    if (modern) {
        return polyfillEmitter.emit();
    }

    polyfillStatus = "loading";
    console.log("[RVS] Loading Valu Search polyfills v" + version);
    loadScript(options.url || POLYFILL_URL, () => {
        polyfillStatus = "done";
        polyfillEmitter.emit();
    });
}

/**
 * Return true when the given object has functions in the given properties
 */
function hasFnProps<Ob extends {}>(ob: Ob | undefined, props: (keyof Ob)[]) {
    if (!ob) {
        return false;
    }

    for (let index = 0; index < props.length; index++) {
        const name = props[index];
        if (name && typeof ob[name] !== "function") {
            return false;
        }
    }

    return true;
}

export function isModern() {
    try {
        // any browser supporting async functions is modern enough
        eval("async function test() {}");
        return true;
    } catch (error) {}

    // Otherwise manually check for the required features
    return (
        hasFnProps([], ["find", "findIndex", "includes"]) &&
        hasFnProps(Array, ["from"]) &&
        hasFnProps(Object, ["assign"]) &&
        hasFnProps("", ["startsWith", "endsWith"]) &&
        hasFnProps(window, ["Promise", "URL", "URLSearchParams", "fetch"])
    );
}

const version = process.env.VERSION || "dev";
const POLYFILL_URL = `https://cdn.search.valu.pro/react-valu-search/v${version}/polyfill.js`;

export function loadScript(url: string, cb?: VoidFunction) {
    const s = window.document.createElement("script");
    s.src = url;
    s.type = "text/javascript";
    if (cb) {
        s.addEventListener("load", cb);
    }
    window.document
        .getElementsByTagName("script")![0]!
        .parentNode!.appendChild(s);
}
